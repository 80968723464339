import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-yellow-500 sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page not found</h1>
              <p className="mt-1 text-base text-gray-500">Please check the URL in the address bar and try again.</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
            <a href="/" className="flex items-center flex-shrink-0 text-gray-900 bg-yellow-400 transform hover:bg-yellow-500  px-4 py-3 lg:px-8 lg:py-5 mt-2 text-sm md:text-base lg:text-lg font-medium rounded-lg focus:outline-none leading-tight space-x-1 lg:space-x-1.5 shadow-sm hover:shadow-md">
                    <span>Go Back Home</span>
                  </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
